import { render, staticRenderFns } from "./DelayPolicyList.vue?vue&type=template&id=6cc0c7b0&scoped=true&"
import script from "./DelayPolicyList.vue?vue&type=script&lang=js&"
export * from "./DelayPolicyList.vue?vue&type=script&lang=js&"
import style0 from "./DelayPolicyList.vue?vue&type=style&index=0&id=6cc0c7b0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_@vue+compiler-sfc@2.7.14_cache-loader@4.1.0_webpack@4.46.0__css-loader@3.6_ju5lionjccu4x6spbwsmqeqe6i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cc0c7b0",
  null
  
)

export default component.exports