<template>
    <div class="page-cont">
        <div class="infoCheck">
            <h2>
                旅游意外险投保确认书
                <em style="color: #f00;font-size: 17px;">(补单)</em>
            </h2>
            <el-row :gutter="4" class="form-ipt">

                <el-col :span="6">
                    <label>渔船号</label>
                    <el-input type="text" v-model="destination"></el-input>
                </el-col>

                <el-col :span="6">
                    <label>合同号</label>
                    <el-input type="text" v-model="teamnumber"></el-input>
                </el-col>
                <el-col :span="6">
                    <label>旅行社</label>
                    <el-select v-model="agencyid" filterable placeholder="请选择旅行社">
                        <el-option v-for="item in travelList" :value="item.TravelAgency_ID" :key="item.TravelAgency_ID"
                            :label="item.TravelAgency_Name"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="6">
                    <label>人数上限</label>
                    <el-input type="text" v-model="personlimit"></el-input>
                </el-col>
                <el-col :span="6">
                    <label>投保人姓名</label>
                    <el-autocomplete type="text" v-model="guide" :fetch-suggestions="nameSearch"
                        placeholder="输入姓名"></el-autocomplete>
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" plain round :disabled="!sendVal" @click="sendFormData">提交</el-button>
                    <el-button type="warning" plain round @click="review">预览</el-button>
                </el-col>
            </el-row>
        </div>

        <el-dialog title="预览" :visible.sync="dilogVis" width="76%">
            <el-row :gutter="16" class="ylwrp">

                <el-col :span="12">
                    <label>渔船号</label>
                    {{ destination }}
                </el-col>
                <el-col :span="12">
                    <label>合同号</label>
                    {{ teamnumber }}
                </el-col>
                <el-col :span="12">
                    <label>旅行社名称</label>
                    {{
                        travelList.filter(item =>  item.TravelAgency_ID == agencyid).length >=1 ?travelList.filter(item =>  item.TravelAgency_ID == agencyid)[0].TravelAgency_Name:""
                    }}
                </el-col>
                <el-col :span="12">
                    <label>人数上限</label>
                    {{ personlimit }}
                </el-col>
                <el-col :span="12">
                    <label>投保人姓名</label>
                    {{ guide }}
                </el-col>



            </el-row>
            <span slot="footer">
                <el-button @click="dilogVis = false">取消</el-button>
                <el-button type="primary" @click="dilogVis = false">好的</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: "",
            visitPersonArr: [
                {
                    InsurancePerson_Name: "",
                    InsurancePerson_CertNo: "",
                },
            ],
            travelList: [],
            travelAgency: "",
            total: 0,
            agencyid:"",
            Policy_IsEvery: 0,
            Policy_Money: 0,
            outPersonArr: [
                {
                    InsurancePerson_Name: "",
                    InsurancePerson_CertNo: "",
                    InsurancePerson_Gender: "",
                    InsurancePerson_Birthday: "",
                },
            ],
            companyid: 0,
            productsName: ["境内", "境外"],
            productsName1: [
                "境内(普通)",
                "境外(普通)",
                "境内(高端)",
                "境外(高端)",
                "境内(普通含延误险)",
                "境外(普通含延误险)",
                "境内(高端含延误险)",
                "境外(高端含延误险)",
            ],
            Policy_Delay: 0,
            personimage: "",
            totalperson: 0,
            personCount: 0,
            guide: "",
            operator: "",
            finalDate: "",
            starttime: "",
            day: "",
            teamnumber: "",
            personlimit:10,
            Policy_Name: "",
            policyid: "",
            policyItem: "",
            reviewSee: false,
            pass1: "",
            pass2: "",
            pass3: "",
            pass4: "",
            pass5: "",
            sendVal: true,
            quality: "",
            logotime: "",
            destination: "",
            delay: "",
            dilogVis: false,
            beforeSend: false,
            onceClick: false,
            tabName: "withTxt",
            ProductList: [],
            errArr: [],
            JDNames: [],
            reviewText: "",
            reviewType: "",
        };
    },
    mounted() {
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.companyid = sessionStorage.getItem("companyid");
        this.pullTravelList();
        this.name = userInfo.TravelAgency_Name;
        this.getJDNames();
    },
    methods: {
        add0(num) {
            return num < 10 ? "0" + num : num;
        },
        pullTravelList() {
            this.ipost(
                "/AdminTravel/TravelAgency.ashx?type=select",
                {
                    pagesize: 999,
                },
                (res) => {
                    this.travelList = res.list;
                }
            );
        },
        reviewBD(type) {
            this.reviewType = type;
            this.reviewSee = true;
        },
        sendReviewData() {
            let url = "";
            if (this.reviewType == "withTxt") {
                url =
                    "/AdminTravel/InsuranceTravelPerson.ashx?type=autorecognition";
            } else {
                url =
                    "/AdminTravel/InsuranceTravelPerson.ashx?type=autorecognitionpassport";
            }
            this.ipost(
                url,
                {
                    tmp: this.reviewText,
                },
                (res) => {
                    this.reviewSee = false;

                    if (res.list.length == 0) {
                        this.$message({
                            message: "未识别到有效信息",
                            type: "warning",
                        });
                        return;
                    }

                    if (this.reviewType == "withTxt") {
                        let tempArr = res.list.map((item) => {
                            return {
                                InsurancePerson_Name: item.InsurancePerson_Name,
                                InsurancePerson_CertNo:
                                    item.InsurancePerson_CertNo.trim(),
                                check: false,
                            };
                        });
                        if (
                            this.visitPersonArr.length == 1 &&
                            this.visitPersonArr[0].InsurancePerson_Name == "" &&
                            this.visitPersonArr[0].InsurancePerson_CertNo == ""
                        ) {
                            this.visitPersonArr = tempArr;
                        } else {
                            this.visitPersonArr =
                                this.visitPersonArr.concat(tempArr);
                        }
                    } else {
                        let tempArr = res.list.map((item) => {
                            return {
                                InsurancePerson_Name: item.InsurancePerson_Name,
                                InsurancePerson_CertNo:
                                    item.InsurancePerson_CertNo.trim(),
                                InsurancePerson_Birthday:
                                    item.InsurancePerson_Birthday,
                                InsurancePerson_Gender:
                                    item.InsurancePerson_Gender,
                                check: false,
                            };
                        });
                        if (
                            this.outPersonArr.length == 1 &&
                            this.outPersonArr[0].InsurancePerson_Name == "" &&
                            this.outPersonArr[0].InsurancePerson_CertNo == "" &&
                            this.outPersonArr[0].InsurancePerson_Gender == "" &&
                            this.outPersonArr[0].InsurancePerson_Birthday == ""
                        ) {
                            this.outPersonArr = tempArr;
                        } else {
                            this.outPersonArr =
                                this.outPersonArr.concat(tempArr);
                        }
                    }
                }
            );
        },
        checkboxChange(val, indx) {
            let arrName =
                this.tabName == "withTxt" ? "visitPersonArr" : "outPersonArr";
            if (val) {
                this[arrName][indx].check = true;
            } else {
                this[arrName][indx].check = false;
            }
        },
        deleteCheck() {
            let tempArr = [];
            if (this.tabName == "withTxt") {
                tempArr = this.visitPersonArr.filter((item) => {
                    if (item.check == false || item.check == undefined)
                        return true;
                });

                this.visitPersonArr = [];
                this.visitPersonArr = tempArr;
                console.log(this.visitPersonArr);
            } else {
                tempArr = this.outPersonArr = this.outPersonArr.filter(
                    (item) => {
                        if (item.check == false || item.check == undefined)
                            return true;
                    }
                );

                this.outPersonArr = [];
                this.outPersonArr = tempArr;
                console.log(this.outPersonArr);
            }
        },
        deleteAll() {
            if (this.tabName == "withTxt") {
                this.visitPersonArr = [];
            } else {
                this.outPersonArr = [];
            }
        },
        getJDNames() {
            this.ipost(
                "/AdminTravel/InsuranceTravel.ashx?type=recentoperator",
                null,
                (res) => {
                    this.JDNames = res.list;
                    //                    console.log("JdName",this.JDNames)
                }
            );
        },
        nameSearch(qs, cb) {
            let tempArr = this.JDNames.map((item) => {
                return {
                    name: item.Insurance_Operator,
                    value: item.Insurance_Operator,
                };
            });
            cb(tempArr);
        },
        querySearch(qs, cb) {
            cb([
                { name: "男", value: "男" },
                { name: "女", value: "女" },
            ]);
        },
        switchTab(e) {
            console.log("switch tab name:", e.name);
            this.tabName = e.name;
        },
        insuraceModel(n) {
            let temp = "/AdminTravel/travelperson.xlsx";
            if (n == 1) {
                temp = "/AdminTravel/travelpersonpassport.xlsx";
            }
            window.open(temp, "_blank");
        },
        setImgUrl(res) {
            this.personimage = res.url;
            console.log("personimage", this.personimage);
        },
        insertPersons(res) {
            if (res.url !== "") {
                this.ipost(
                    "/AdminTravel/InsuranceTravelPerson.ashx?type=import",
                    {
                        url: res.url,
                    },
                    (res) => {
                        if (this.tabName == "withTxt") {
                            let tempArr = res.list.map((item) => {
                                return {
                                    InsurancePerson_Name:
                                        item.InsurancePerson_Name,
                                    InsurancePerson_CertNo:
                                        item.InsurancePerson_CertNo.trim(),
                                    check: false,
                                };
                            });
                            if (
                                this.visitPersonArr.length == 1 &&
                                this.visitPersonArr[0].InsurancePerson_Name ==
                                "" &&
                                this.visitPersonArr[0].InsurancePerson_CertNo ==
                                ""
                            ) {
                                this.visitPersonArr = tempArr;
                            } else {
                                this.visitPersonArr =
                                    this.visitPersonArr.concat(tempArr);
                            }
                        } else {
                            let tempArr = res.list.map((item) => {
                                return {
                                    InsurancePerson_Name:
                                        item.InsurancePerson_Name,
                                    InsurancePerson_CertNo:
                                        item.InsurancePerson_CertNo.trim(),
                                    InsurancePerson_Birthday:
                                        item.InsurancePerson_Birthday,
                                    InsurancePerson_Gender:
                                        item.InsurancePerson_Gender,
                                    check: false,
                                };
                            });
                            if (
                                this.outPersonArr.length == 1 &&
                                this.outPersonArr[0].InsurancePerson_Name ==
                                "" &&
                                this.outPersonArr[0].InsurancePerson_CertNo ==
                                "" &&
                                this.outPersonArr[0].InsurancePerson_Gender ==
                                "" &&
                                this.outPersonArr[0].InsurancePerson_Birthday ==
                                ""
                            ) {
                                this.outPersonArr = tempArr;
                            } else {
                                this.outPersonArr =
                                    this.outPersonArr.concat(tempArr);
                            }
                        }
                    }
                );
            }
        },
        sendFormData(bool) {
            console.log(typeof bool);
            if (this.checkFormData()) {
                let tempUrl = "/AdminTravel/InsuranceTravel.ashx?type=insertparambyagencyadminnoperson";
                let postData = {
                    guide: this.guide,
                    teamnumber: this.teamnumber,
                    personlimit: this.personlimit,
                    destination: this.destination,
                    agencyid: this.agencyid,
                };
                

                if (this.sendVal) {
                    this.sendVal = false;
                    this.ipost(
                        tempUrl,
                        postData,
                        (res) => {
                            this.sendVal = true;
                            this.beforeSend = false;
                            this.$alert("下单成功", {
                                dangerouslyUseHTMLString: true,
                            });
                            this.resetForm();
                            console.log("res:", res);
                            // Location.reload(true)
                        },
                        () => {
                            this.sendVal = true;
                        }
                    );
                }
            }
        },
        setProudctName(evt) {
            let current = this.ProductList.find(
                (item) => item.Policy_Name == this.policyItem
            );
            this.policyid = current.Policy_ID;
            this.Policy_IsEvery = current.Policy_IsEvery;
            this.Policy_Money = current.Policy_Money;
            console.log("policyid:", current.Policy_ID);

            this.Policy_Name = this.policyItem;
        },
        resetForm() {
            this.guide = "";
            this.teamnumber = "";
            this.personlimit = 10;
            this.destination = "";
            this.agencyid = "";
        },
        dateCheck(e) {
            if (e) {
                let today = new Date();

                let nowMilitime = Date.parse(
                    `${today.getFullYear()}-${today.getMonth() + 1
                    }-${today.getDate()} 12:00:00`
                );

                let nowTimeNum = Date.now();

                // console.log("nowMilitime",nowMilitime)
                // console.log("nowTimeNum",nowTimeNum)
                let currentDayStr = `${today.getFullYear()}-${this.add0(
                    today.getMonth() + 1
                )}-${this.add0(today.getDate())}`;

                console.log(
                    "starttime",
                    this.starttime,
                    "currentDayStr",
                    currentDayStr
                );

                if (this.starttime == currentDayStr) {
                    if (this.TravelAgency_ID == 233) {
                        this.logotime = new Date(
                            nowTimeNum + 30 * 60 * 1000
                        ).toLocaleTimeString("zh-CN");
                    } else {
                        this.logotime = new Date(
                            nowTimeNum + 120 * 60 * 1000
                        ).toLocaleTimeString("zh-CN");
                    }
                } else {
                    this.logotime = "00:00:00";
                }

                console.log("logotime", this.logotime);

                this.computeDate(Date.parse(this.starttime), this.day);
            }
        },
        dayChange(e) {
            console.log(e);
            if (typeof Number(e) == "number") {
                this.day = Math.floor(e);

                this.computeDate(Date.parse(this.starttime), this.day);

                // 置空下拉选择
                this.quality = "";
                this.policyItem = "";
                this.policyid = "";
                this.ProductList.length = 0;
            } else {
                this.$message("请输入数字");
            }
        },
        computeDate(dateMiliTime, num) {
            if (dateMiliTime == "" || num == "" || num == 0) {
                return;
            }

            let miliTime = dateMiliTime + (num - 1) * 1000 * 60 * 60 * 24;

            // console.log(miliTime,new Date(miliTime).toLocaleDateString('zh-CN'))

            this.finalDate = `${new Date(miliTime).getFullYear()}-${this.add0(
                new Date(miliTime).getMonth() + 1
            )}-${this.add0(new Date(miliTime).getDate())}`;
        },
        getPolicyList() {
            this.ProductList.length = 0;
            this.policyItem = "";
            this.policyid = "";
            this.ipost(
                "/AdminTravel/PolicyTravel.ashx?type=selectbyagency",
                {
                    pageindex: 1,
                    pagesize: 666,
                    day: this.day,
                    quality: this.quality,
                },
                (res) => {
                    this.ProductList = res.list;
                    this.Policy_Money = res.list[0].Policy_Money;
                    this.Policy_IsEvery = res.list[0].Policy_IsEvery;
                    this.Policy_Name = this.policyItem =
                        res.list[0].Policy_Name || "";
                    this.policyid = res.list[0].Policy_ID || "";
                    // this.total = res.count
                    console.log("产品列表 res:", res);
                }
            );
        },
        review() {
            if (this.checkFormData()) {
                this.dilogVis = true;
            }
        },
        addPerson() {
            this.visitPersonArr.push({
                InsurancePerson_Name: "",
                InsurancePerson_CertNo: "",
                check: false,
            });
        },
        confirmPerson() {
            this.errArr = [];

            for (let i = 0; i < this.visitPersonArr.length; i++) {
                let current = this.visitPersonArr[i];

                if (
                    current.InsurancePerson_Name == "" ||
                    current.InsurancePerson_CertNo == "" ||
                    current.InsurancePerson_CertNo.trim().length != 18
                ) {
                    this.errArr.push(current.InsurancePerson_CertNo);
                }
            }

            if (this.errArr.length > 0) {
                this.$message("个别人员信息有误，请检查");

                let firstIndx = this.visitPersonArr.findIndex(
                    (item) => item.InsurancePerson_CertNo == this.errArr[0]
                );

                let aimHeight = 56 + 83 * (firstIndx + 1);

                console.log("aimHeight", aimHeight);
                document
                    .querySelector(".person-list-wrp")
                    .scrollTo(0, aimHeight);

                return false;
            }

            this.ipost(
                "/AdminTravel/InsuranceTravel.ashx?type=checkpersonlist",
                {
                    personlist: JSON.stringify(this.visitPersonArr),
                },
                (res) => {
                    this.$message("校验全部通过...");
                },
                (res) => {
                    this.errArr = res.list.map(
                        (item) => item.InsurancePerson_CertNo
                    );
                    console.log("errArr", this.errArr);

                    let firstIndx = this.visitPersonArr.findIndex(
                        (item) => item.InsurancePerson_CertNo == this.errArr[0]
                    );

                    let aimHeight = 56 + 83 * (firstIndx + 1);

                    console.log("aimHeight", aimHeight);
                    document
                        .querySelector(".person-list-wrp")
                        .scrollTo(0, aimHeight);
                }
            );
        },
        addOPerson() {
            this.outPersonArr.push({
                InsurancePerson_Name: "",
                InsurancePerson_CertNo: "",
                InsurancePerson_Gender: "",
                InsurancePerson_Birthday: "",
                check: false,
            });
        },
        sendDelete(index, n) {
            if (n == 1) {
                this.outPersonArr.splice(index, 1);
            } else {
                let errIndx = this.errArr.findIndex(
                    (item) =>
                        item ==
                        this.visitPersonArr[index].InsurancePerson_CertNo
                );
                if (errIndx != -1) {
                    this.errArr.splice(errIndx, 1);
                }
                this.visitPersonArr.splice(index, 1);
            }
        },
        checkFormData() {

            if (this.guide == "") {
                this.$message("请填写投保人姓名");
                return false;
            }
            if (this.destination == "") {
                this.$message("请填写渔船号");
                return false;
            }
            if (this.agencyid == "") {
                this.$message("请选择旅行社");
                return false;
            }

            if (this.teamnumber == "") {
                this.$message("请填写合同号");
                return false;
            }


            if (this.destination == "") {
                this.$message("请填写旅行目的地");
                return false;
            }


            return true;
        },
    },
};
</script>

<style scoped>
.check-box-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
}

.check-box-wrp .el-checkbox {
    margin-top: 40px;
}

.form-input-wrp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.form-input-wrp .el-input {
    width: calc(100% - 120px);
}

.dateSelect {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.dateSelect p {
    color: #999;
    font-size: 14px;
    text-decoration: 0 0 2px #ccc;
    line-height: 37px;
    padding: 0 6px;
    margin: 0;
}

.insurance-btn-wrp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.insurance-btn-wrp .el-button {
    margin-right: 7px;
}

.lbt {
    text-align: center;
    font-size: 13px;
}

.page-cont {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.form-ipt .el-col {
    display: flex;
    margin-bottom: 12px;
}

.form-ipt .el-col label {
    width: 77px;
    font-size: 14px;
}

.infoCheck {
    width: 97%;
    padding: 15px;
    box-sizing: border-box;
    margin: 10px auto;
    border-radius: 6px;
    box-shadow: 0 0 12px #dedede;
    background-color: #fefefe;
}

.person-info {
    height: auto;
    display: flex;
    flex-direction: column;
}

.person-list-wrp {
    max-height: calc(100vh - 385px);
    overflow: hidden;
    overflow-y: auto;
}

.infoCheck h2 {
    margin: 6px 0;
}

.top-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.top-btns .el-button {
    margin-left: 8px;
}

.infoCheck label {
    line-height: 2.7;
}

.destwrp {
    line-height: 40px;
    text-align: center;
}

.btnswrp {
    margin: 5px auto;
    text-align: right;
}

.top-row {
    margin: 5px 0;
}

.top-row h2 {
    margin: 0;
}

.btn-delt .el-button {
    margin-top: 40px;
}

.ylwrp {
    font-size: 16px;
    font-weight: bold;
}

.ylwrp label {
    line-height: 37px;
    margin-right: 12px;
    font-size: 13px;
    color: #a1a1a1;
}

.vis-person {
    margin-top: 17px;
    border-top: 1px dashed #ccc;
}

.err .el-input,
.err .el-input .el-input__inner {
    border: 1px solid #f00 !important;
    background-color: #f00 !important;
    border-radius: 4px;
}

em {
    font-style: normal;
    color: tomato;
}
</style>
