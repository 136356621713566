import Vue from 'vue';
import router from './router';
import ElementUI from 'element-ui';
import { ajax } from "jquery";
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';


Vue.use(ElementUI);

const domin = "https://www.wzsosu.net/";

let wins = "";

Vue.prototype.glb = {
    domin: "https://www.wzsosu.net/"
}


Vue.config.productionTip = false
Vue.prototype.domin = domin;
Vue.prototype.printShow = (cont, person_index) => {

    if (!!wins) {
        wins.close()
    }
    console.log("cont", cont)
    // 传入学校数据列表 调取打印窗口
    let pageCont, divCont, persons, personList;

    let cname = sessionStorage.getItem("companyname")
    // 金华的打印保单
    if (cont.model.Insurance_Company == 12) {
        let minCont = ""

        if (person_index >= 0) {
            let PointItem, PointIndx
            cont.policylist.forEach((item, po_indx) => {
                item.PolicyTravel_PersonList.filter((inItem, inPo_indx) => {
                    if (inItem.InsurancePerson_Name == cont.list[person_index].InsurancePerson_Name) {
                        console.log("point item: ", item, inPo_indx)
                        PointItem = item
                        PointIndx = inPo_indx
                    }
                })
            })

            cont.policylist = [PointItem]
            cont.policylist[0].PolicyTravel_PersonList = [cont.policylist[0].PolicyTravel_PersonList[PointIndx]]
        }

        cont.policylist.forEach((item, po_indx) => {
            let minTop = `<section class="sec-grp" style="position:relative">
                            <ol style="list-style:none;padding:0;margin:0;">
                                <li>
                                    <label>1、保险产品：</label>  ${item.Policy_Name}
                                </li>
                                <li>
                                    <label>2、保险责任：</label>${item.Policy_Brief}
                                </li>
                                <li>
                                    <label>3、投保人数：</label> ${item.PolicyTravel_PersonList.length} 人
                                </li>
                                
                            </ol>
        `
            minCont += minTop

            if (item.PolicyTravel_PersonList.length > 0) {
                let minPersonList = "<tr>"
                item.PolicyTravel_PersonList.forEach((pitem, pindex) => {
                    minPersonList +=
                        `
                <td>${pindex + 1}</td>
                <td>${pitem.InsurancePerson_Name}</td>
                <td>${pitem.InsurancePerson_CertNo}</td>
            `
                    if ((pindex + 1) % 2 == 0) {
                        minPersonList += `</tr><tr>`
                    }
                })
                minPersonList += "</tr>"
                let minPersonsCont =
                    `<section style="text-align: center;">             
                    <table border="0" >
                <thead>
                    <td>序号</td>
                    <td>被保险人姓名</td>
                    <td>被保险人证件</td>
                    <td>序号</td>
                    <td>被保险人姓名</td>
                    <td>被保险人证件</td>
                </thead>
                ${minPersonList}
            </table>
            </section>
            <span class="artcle-sign">已确认</span>
            ${cont.policylist.length - 1 == po_indx ? '' : '<div style="visibility: hidden;break-after: page;"></div>'}
            </section>`
                minCont += minPersonsCont
            }

        })

        divCont = `
        <section class="content">
            <p>尊敬的客户 :</p>
            <p style="position:relative"> 您在本公司汇交的 ${cont.model.Insurance_Number} 投保件，本公司已完成承保手续。<br />
                现将本公司承保的具体情况通知如下：<span class="sure-sign" style="right:60%">已确认</span></p>
            <ol style="list-style:none">
                <li>
                    <label>一、旅行社名称：</label> ${cont.model.Insurance_TravelAgencyName}
                </li>
                <li>
                    <label>二、保险期限(出团时间)：</label> ${cont.model.Insurance_EffectStart} 起 至 ${cont.model.Insurance_EffectEnd}
                </li>
                <li>
                    <label>三、保险费：</label> ${person_index >= 0 ? cont.list[person_index].InsurancePerson_Money : cont.model.Insurance_TotalMoney} 元
                </li>
                <li>
                    <label>四、受益人：</label> 身故保险金受益人为法定继承人，除身故保险金外的其他保险金受益人为被保险人本人。
                </li>
                <li>
                    <label>五、目的地：</label> ${cont.model.Insurance_Destination} 团队号： ${cont.model.Insurance_Teamnumber || '暂无'} 计调： ${cont.model.Insurance_Operator || '暂无'} 
                </li>
            </ol>
            <ol style="list-style:none;font-size:13px">
                <li>
                    <label>六、保险特约</label>
                    <div style="padding-left:12px">
                        ${cont.term.replace(/\/r\/n/g, '<br/>')}
                    </div>
                </li>
                <li>
                    <label>七、全国统一客户服务电话：95519</label>
                </li>
                <li>
                    <label>八、客户可登陆至www.e-chinalife.com查询本公司最新偿付能力信息、保单信息及上述相关条款内容。</label>
                </li>
            </ol>

        <p class="comp-sign">
        ${cname}
            <span class="sure-sign">已确认</span>
        </p>
            <div style="visibility: hidden;break-after: page;"></div>
            <h2 class="ins-tit">被保险人清单</h2> 
                ${minCont}
        </section>`
        // console.log("minCont",minCont)
    } else {
        if (person_index >= 0) {
            cont.list = [cont.list[person_index]]
        }
        if (cont.list.length > 0) {

            personList = "<tr>"
            cont.list.filter(item => item.InsurancePerson_Status == 1).forEach((item, index) => {

                personList +=
                    `
                <td>${index + 1}</td>
                <td>${item.InsurancePerson_Name}</td>
                <td>${item.InsurancePerson_CertNo}</td>
            `
                if ((index + 1) % 2 == 0) {
                    personList += `</tr><tr>`
                }
            })
            personList += "</tr>"
            persons =
                `<table border="0" >
                <thead>
                    <td>序号</td>
                    <td>被保险人姓名</td>
                    <td>被保险人证件</td>
                    <td>序号</td>
                    <td>被保险人姓名</td>
                    <td>被保险人证件</td>
                </thead>
                ${personList}
            </table>
            <span class="sure-sign">已确认</span>
            <div style="visibility: hidden;break-after: page;"></div>`

        } else {
            persons = `<img style="width:95%" src='${domin + cont.model.Insurance_PersonImage}' />`
        }

        // console.log("persons", persons)

        divCont = `
    <section class="content">
        <p>尊敬的客户 :</p>
        <p style="position:relative"> 您在本公司汇交的 ${cont.model.Insurance_Number} 投保件，本公司已完成承保手续。<br />
            现将本公司承保的具体情况通知如下：<span class="sure-sign" style="right:60%">已确认</span></p>
        <ol style="list-style:none">
            <li>
                <label>一、旅行社名称：</label> ${cont.model.Insurance_TravelAgencyName}
            </li>
            <li>
                <label>二、保险期限(出团时间)：</label> ${cont.model.Insurance_EffectStart} 起 至 ${cont.model.Insurance_EffectEnd}
            </li>

            <li>
                <label>三、受益人：</label> 身故保险金受益人为法定继承人，除身故保险金外的其他保险金受益人为被保险人本人。
            </li>
            <li>
                <label>四、目的地：</label> ${cont.model.Insurance_Destination} 团队号： ${cont.model.Insurance_Teamnumber || '暂无'} 计调： ${cont.model.Insurance_Operator || '暂无'} 
            </li>
            <li>
                <label>五、保险责任：</label> ${cont.model.Insurance_PolicyBrief}
            </li>
        </ol>
        <ol style="list-style:none;font-size:13px">
            <li>
                <label>六、保险特约</label>
                <div style="padding-left:12px">
                    ${cont.term.replace(/\r\n/g, '<br/>')}
                </div>
            </li>
            <li>
                <label>七、全国统一客户服务电话：95519</label>
            </li>
            <li>
                <label>八、客户可登陆至www.e-chinalife.com查询本公司最新偿付能力信息、保单信息及上述相关条款内容。</label>
            </li>
        </ol>

    <p class="comp-sign">
    ${cname}
        <span class="sure-sign">已确认</span>
    </p>
    </section>
    <div style="visibility: hidden;break-after: page;"></div>
    <section style="text-align: center; position:relative">
        <p style="text-align:left;font-size:13px;display:${cont.policylist[0].Policy_HidePrice == 0 && person_index == undefined ? 'block' : 'none'}">
            <label>保险费：</label> ${cont.model.Insurance_TotalMoney} 元
        </p>
        <h2 class="ins-tit">被保险人清单</h2>
                ${persons}
    </section>`
    }

    // console.log("cont.term:",cont.term.replace(/\r\n/g,'<br/>'))
    pageCont =
        `<!DOCTYPE html>
        <html lang="zh_CN">
        
        <head>
            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>打印</title>
            <style>
                body {
                    margin: 12px auto;
                    padding: 12px;
                    max-width: 960px;
                }
                ol{
                    margin:0;
                    padding:0;
                }
                h2 {
                    text-align: center;
                }
                p{
                    margin:6px 0;
                }
                td{
                    border: 1px solid #999;
                    padding: 3px 6px;  
                }
                table{
                    width: 100%;
                    border-spacing: 0;
                    border: 2px solid #000;
                }
                .sec-grp{
                    padding: 2px 12px;
                    margin: 2px auto;
                }
                .content{
                    font-size: 13px;
                    line-height: 1.3;
                }
                .comp-sign{
                    margin-top:23px;
                    text-align:right;
                    position:relative;
                }
                .sure-sign{
                    border:3px solid #f00;
                    font-weight:bold;
                    color:#f00;
                    border-radius:4px;
                    padding:10px 16px;
                    font-size:23px;
                    position: absolute;
                    right:16px;
                    bottom:12px;
                    transform:rotate(25deg) scale(1.3)
                }
                .artcle-sign{
                    border:3px solid #f00;
                    font-weight:bold;
                    color:#f00;
                    border-radius:4px;
                    padding:10px 16px;
                    font-size:23px;
                    position: absolute;
                    top:37px;
                    left:77px;
                    transform:rotate(25deg) scale(1.3)
                }
                .ins-tit{
                    font-size: 22px;
                    text-align: center;
                    margin: 12px auto;

                }
            </style>
        </head>
        
        <body>
            <div id="tips" style="background: #333;color: #fff;padding: 12px;position:absolute;top:50%;left:50%;transform:translateX(-50%);z-index:999">调取打印中,请等待...倒计时<span id="count">1</span>秒</div>
            <p style="text-align:center"> <img style="display:${cont.model.Insurance_Company == 12 ? 'none' : 'inline-block'};width:70%;max-width:500px" src="https://www.wzsosu.net/MPInquery/pics/rslogo.jpg" /> </p>
            <h2>${cname}</h2>
            <h2>旅游意外保险投保确认书</h2>
                ${divCont}
            

        </body> 
        
        </html>
                `

    wins = window.open("", "print")
    if (!wins) {
        alert("您的浏览器是否拦截了打印的窗口页面,请设置后再重试!")
    } else {
        wins.document.write(pageCont)

        let scriptNode = wins.document.createElement("script")
        scriptNode.innerText = `                                
                        let ocount = document.querySelector("#count");
                        let otip = document.querySelector("#tips");
                        let odiv = document.querySelectorAll(".page");
                        console.log("page length:",odiv.length);                                 
                        let mus = 3;
                        ocount.innerText = mus;
                        let timeInVal = setInterval(function () {
                            if (mus > 0) {
                                mus -= 1;
                                ocount.innerText = mus;
                            } else {
                                clearInterval(timeInVal);
                                otip.style.opacity = 0;
                                print()
                            }

                        }, 1000)
                    `
        wins.document.body.appendChild(scriptNode)

    }
}

let errfuc = function (res) {
    let tips = "";

    switch (res.result) {
        case "noexist":
            tips = "用户不存在";
            break;
        case "pwderror":
            tips = "密码有误";
            break;
        case "nodestination":
            tips = "目的地不能为空";
            break;
        case "errorday":
            tips = "天数需要大于0";
            break;
        case "noperson":
            tips = "被保险人列表没有数据";
            break;
        case "areanameexsit":
            tips = "分支公司名称已存在";
            break;
        case "areaaccountexsit":
            tips = "分支公司登录账号已存在";
            break;
        case "notyourarea":
            tips = "不是你的分支";
            break;
        case "nopolicy":
            tips = "产品编号无效";
            break;
        case "daylimit":
            tips = "投保天数";
            break;
        case "birthdayerror":
            tips = "生日有误";
            break;
        case "overtime":
            tips = "超过可变更时间";
            break;
        case "certnoerror":
            let tempStr = ""
            res.list.forEach(item => {
                tempStr += "第 " + item.InsurancePerson_Number + " 位被保人 " + item.InsurancePerson_Name + " 身份证号码 " + item.InsurancePerson_CertNo + " 有误\n";
            });
            tips = tempStr;
            break;
        case "fail":
            tips = "该请求操作失败";
            break;
        case "certnoinsurance":
            tips = "第" + res.number + "位被保人身份证已投保";
            break;
        case "overtodaytime":
            tips = "超过投保当天时间";
            break;
        case "agencynameexsit":
            tips = "旅行社名称已存在";
            break;
        case "errorstatus":
            tips = "提现状态有误";
            break;
        case "certnumbererror":
            tips = "各别被保人身份证有误，请先校验后更改完，再尝试提交";
            break;
        case "moneyless":
            tips = "余额不足";
            break;
        case "birthdayerror":
            tips = "生日格式有误";
            break;
        case "agencyaccountexsit":
            tips = "旅行社登录账号已存在";
            break;
        case "overagerange":
            tips = "第" + res.number + "位被保人年龄不符合要求";
            break;
        case "certnorepeat":
            tips = "第" + res.number + "位被保人份证重复";
            break;
        case "nologin":
            tips = "登陆失效，请重新登陆";
            location.href = domin + "/web/#/"
            break;
        default:
            tips = "网络请求异常/拥堵,请稍后再试...";
            console.log(res)
            break;
    }

    if (res.result != "nologin") {
        alert(tips);
    }

    return false;
};


Vue.prototype.iget = function (url, data, succ) {
    ajax({
        url: url,
        method: "GET",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
        },
        data: data,
    })
        .then((res) => {
            if (res.result !== "success") {
                errfuc(res);
            }

            succ(res);
        })
        .catch((err) => {
            console.log(err);
        });
};
// post请求方法 ,无前置loading效果
Vue.prototype.ipst = function (url, data, succ) {
    ajax({
        url: url,
        method: "POST",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
        },
        data: data,
    })
        .then((res) => {
            if (res.result !== "success") {
                errfuc(res);
            }
            succ(res);
        })
        .catch((err) => {
            console.log(err);
        });
};
Vue.prototype.ajx = (url, data) => {
    return ajax({
        url: url,
        method: "POST",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
        },
        data: data,
    })
}
Vue.prototype.ipost = function (url, data, succ, fail) {
    const loading = this.$loading({
        target: document.querySelector('main'),
        text: '加载中...'
    })
    ajax({
        url: url,
        method: "POST",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
        },
        data: data,
    })
        .then((res) => {
            loading.close()
            if (res.result !== "success") {
                fail && fail(res)
                errfuc(res);
                return
            }
            succ(res);
        })
        .catch((err) => {
            loading.close()
            console.log("请求出错:", err);
        });
};



new Vue({
    router,
    render: h => h(App)
}).$mount('#app')