<template>
<div class="navs">
    <!-- <div class="logo"><img src="../assets/vlogo.jpg" alt="logo" /></div> -->
    <el-menu mode="vertical" default-active="1" background-color="#496cfc" text-color="#c5d3f8" active-text-color="#fff">
        <el-submenu index="1">
            <template slot="title">代理人管理</template>
            <el-menu-item-group>
                <el-menu-item index="1-1">
                    <router-link to="/home/travelAgencyList"> 旅行社列表 </router-link>
                </el-menu-item>
            </el-menu-item-group>
        </el-submenu>
        <el-submenu index="2">
            <template slot="title">产品管理</template>
            <el-menu-item-group>
                <el-menu-item index="2-1">
                    <router-link to="/home/travelProuductList"> 产品维护 </router-link>
                </el-menu-item>
            </el-menu-item-group>
        </el-submenu>
        <el-submenu index="6">
            <template slot="title">补单管理</template>
            <el-menu-item-group>
                <el-menu-item index="6-1">
                    <router-link v-if="userInfo.TravelAgency_Authority==0&&userInfo.TravelAgency_Style==10 " to="/home/adminPolicySellYG"> 即刻补单 </router-link>
                    <router-link v-else to="/home/adminPolicySells"> 即刻补单 </router-link>
                </el-menu-item>
            </el-menu-item-group>
        </el-submenu>
        <el-submenu index="5">
            <template slot="title">分支管理</template>
            <el-menu-item-group>
                <el-menu-item index="5-1">
                    <router-link to="/home/travelAreaList"> 分支列表 </router-link>
                </el-menu-item>
            </el-menu-item-group>
        </el-submenu>
        <el-submenu index="3">
            <template slot="title">查询统计</template>
            <el-menu-item-group>
                <el-menu-item index="3-1">
                    <router-link to="/home/travelPolicyList"> 查询保单 </router-link>
                </el-menu-item>
                <el-menu-item index="3-2">
                    <router-link to="/home/orderCount"> 保单统计 </router-link>
                </el-menu-item>
            </el-menu-item-group>
        </el-submenu>
        <el-submenu index="4">
            <template slot="title">提现相关</template>
            <el-menu-item-group>
                <el-menu-item index="4-1">
                    <router-link to="/home/cashCount"> 提现统计 </router-link>
                </el-menu-item>
                <el-menu-item index="4-2">
                    <router-link to="/home/cashList"> 提现列表 </router-link>
                </el-menu-item>
            </el-menu-item-group>
        </el-submenu>
    </el-menu>
</div>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
    data() {
        return {
            userInfo:JSON.parse(sessionStorage.getItem('userInfo'))
        }
    }
};
</script>

<style scoped>
.navs {
    height: 100%;
    background-color: #496cfc;
    overflow: hidden;
    overflow-y: auto;
}

.el-menu-item a {
    color: rgb(197 211 248);
    display: block;
}

.el-menu-item.is-active a {
    color: #fff;
}

.logo {
    background-color: #496cfc;
    height: 60px;
    text-align: center;
}

.logo img {
    height: 45px;
    margin-top: 7.5px;
}
</style>
