<template>
    <div>
        <el-collapse v-model="openName">
            <el-collapse-item name="1">
                <template slot="title">
                    <i class="header-icon el-icon-s-operation"></i>筛选
                    <div class="totalwrp">
                        <span>总人数:{{ personcountall }}</span>
                        <span>总金额:{{ moneyall }}</span>
                        <span>总条数:{{ total }}</span>
                    </div>
                </template>
                <div class="dataFilterWrap">
                    <el-row :gutter="6">
                        <el-col :span="5">
                            <label>时间类型</label>
                            <el-select
                                v-model="timemethod"
                                placeholder="请选择"
                            >
                                <el-option
                                    :value="0"
                                    label="提交时间"
                                ></el-option>
                                <el-option
                                    :value="1"
                                    label="起保时间"
                                ></el-option>
                            </el-select>
                        </el-col>
                        <el-col
                            :span="9"
                            style="display: flex; align-items: center"
                            class="date-select-wrp"
                        >
                            <p class="pgf-tips">
                                如需提取2024年1月份数据，请选择2024年1月1日-2024年2月1日
                            </p>
                            <label>时间范围</label>
                            <el-date-picker
                                v-model="starttime"
                                type="date"
                                placeholder="选择开始日期"
                                format="yyyy 年 MM 月 dd 日"
                                value-format="yyyy-MM-dd"
                            >
                            </el-date-picker>
                            <el-date-picker
                                v-model="endtime"
                                type="date"
                                placeholder="选择结束日期"
                                format="yyyy 年 MM 月 dd 日"
                                value-format="yyyy-MM-dd"
                            >
                            </el-date-picker>
                            <div class="two-button">
                                <el-tooltip
                                    content="月跨度结束日，为次月的一号"
                                    placement="top"
                                    effect="light"
                                >
                                    <a href="javascript:;" @click="prevMonth">
                                        <img
                                            class="moon-icon"
                                            src="../assets/moon.png"
                                        />
                                        上月</a
                                    >
                                </el-tooltip>
                                <el-tooltip
                                    content="月跨度结束日，为次月的一号"
                                    placement="bottom"
                                    effect="light"
                                >
                                    <a href="javascript:;" @click="curtMonth">
                                        <img
                                            class="moon-icon"
                                            src="../assets/moon.png"
                                        />
                                        当月</a
                                    >
                                </el-tooltip>
                            </div>
                        </el-col>
                        <el-col :span="5">
                            <label>身份证号</label>
                            <el-input
                                type="text"
                                v-model="searchcertno"
                                clearable
                            ></el-input>
                        </el-col>
                        <el-col :span="5">
                            <label>团单号</label>
                            <el-input
                                type="text"
                                v-model="teamnumber"
                                clearable
                            ></el-input>
                        </el-col>
                    </el-row>
                    <el-row :gutter="6">
                        <el-col :span="6">
                            <label>订单号</label>
                            <el-input
                                type="text"
                                v-model="searchnumber"
                                clearable
                            ></el-input>
                        </el-col>
                        <el-col :span="6"
                            ><label>保单状态</label>
                            <el-select v-model="status" placeholder="请选择">
                                <el-option :value="1" label="提交"></el-option>
                                <!-- <el-option :value="2" label="撤单"></el-option> -->
                            </el-select>
                        </el-col>
                        <el-col :span="6"
                            ><label>保险类型</label>
                            <el-select
                                v-model="policyquality"
                                placeholder="请选择"
                                @change="getPolicyList"
                            >
                                <el-option :value="1" label="境内"></el-option>
                                <el-option :value="2" label="境外"></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="6">
                            <el-button
                                type="success"
                                round
                                icon="el-icon-search"
                                @click="pullList(1)"
                                >筛选</el-button
                            >

                            <el-button
                                type="primary"
                                round
                                plain
                                @click="exportOrderList"
                                >导出订单</el-button
                            >

                            <el-button
                                type="primary"
                                round
                                plain
                                @click="exportDetailList"
                                >导出明细</el-button
                            >
                        </el-col>
                    </el-row>
                </div>
            </el-collapse-item>
        </el-collapse>
        <el-dialog
            :visible.sync="downloadShowDetail"
            title="导出明细"
            width="37%"
            modal
            :close-on-click-modal="false"
        >
            <p><i class="el-icon-info"></i> 提示</p>
            <p v-if="downloadDetailUrl == ''">
                <i class="el-icon-loading"></i> 生成 EXCEL 文件中......
            </p>
            <div v-else>
                <p>生成 EXCEL 文件完成</p>
                <p class="downBtn">
                    <a :href="domin + downloadDetailUrl">点我下载</a>
                </p>
            </div>
        </el-dialog>
        <el-dialog
            :visible.sync="downloadShow"
            title="导出订单"
            width="37%"
            modal
            :close-on-click-modal="false"
        >
            <p><i class="el-icon-info"></i> 提示</p>
            <p v-if="downloadUrl == ''">
                <i class="el-icon-loading"></i> 生成 EXCEL 文件中......
            </p>
            <div v-else>
                <p>生成 EXCEL 文件完成</p>
                <p class="downBtn">
                    <a :href="domin + downloadUrl">点我下载</a>
                </p>
            </div>
        </el-dialog>
        <el-table
            :data="orders"
            stripe
            fit
            tooltip-effect="dark"
            class="orderTable"
            :row-class-name="addPorpClass"
        >
            <el-table-column label="序号" width="70">
                <template slot-scope="scope">{{ scope.$index }}</template>
            </el-table-column>
            <el-table-column label="目的地" width="120" show-overflow-tooltip>
                <template slot-scope="scope">{{
                    scope.row.Insurance_Destination
                }}</template>
            </el-table-column>
            <el-table-column label="有效期限" width="360" show-overflow-tooltip>
                <template slot-scope="scope">{{
                    scope.row.Insurance_EffectStart +
                    " —— " +
                    scope.row.Insurance_EffectEnd
                }}</template>
            </el-table-column>
            <el-table-column label="产品" show-overflow-tooltip>
                <template slot-scope="scope">{{
                    scope.row.Policy_Name
                }}</template>
            </el-table-column>

            <el-table-column label="天数" width="70">
                <template slot-scope="scope">{{
                    scope.row.Insurance_TotalDay
                }}</template>
            </el-table-column>

            <el-table-column label="人数" width="70">
                <template slot-scope="scope">{{
                    scope.row.Insurance_TotalPerson
                }}</template>
            </el-table-column>
            <!-- <el-table-column label="保费" width="100">
                <template slot-scope="scope">{{
                    scope.row.Policy_IsEvery == 0 ? Number(scope.row.Policy_Money).toFixed(2) : Number(scope.row.Policy_Money * scope.row.Insurance_TotalPerson).toFixed(2)
                }}</template>
            </el-table-column> -->
            <el-table-column label="编号" width="150" show-overflow-tooltip>
                <template slot-scope="scope">{{
                    scope.row.Insurance_Number
                }}</template>
            </el-table-column>
            <el-table-column label="状态" width="70">
                <template slot-scope="scope">{{
                    scope.row.Insurance_Status == 1 ? "提交" : "撤单"
                }}</template>
            </el-table-column>
            <el-table-column label="提交时间" width="160">
                <template slot-scope="scope">{{
                    scope.row.Insurance_Create
                }}</template>
            </el-table-column>
            <el-table-column label="总额" width="100">
                <template slot-scope="scope">{{
                    Number(scope.row.Insurance_DelayMoney).toFixed(2)
                }}</template>
            </el-table-column>
            <el-table-column
                label="操作"
                width="320"
                fixed="right"
                class-name="btnCol"
            >
                <template slot-scope="scope">
                    <el-button
                        v-if="companyid == 12"
                        type="default"
                        size="mini"
                        plain
                        @click="getDown(scope.row.Insurance_ID)"
                        >下载</el-button
                    >
                    <el-button
                        type="default"
                        size="mini"
                        plain
                        slot="reference"
                        @click="printNow(scope.row)"
                        >打印</el-button
                    >
                    <el-button
                        type="primary"
                        size="mini"
                        plain
                        @click="getDetails(scope.row.Insurance_ID)"
                        >详情</el-button
                    >
                    <!-- <el-popconfirm
                        v-if="scope.row.Insurance_Status != 2"
                        confirm-button-text="好的"
                        cancel-button-text="再考虑"
                        icon="el-icon-info"
                        icon-color="red"
                        title="确定要作废吗？"
                        @confirm="setCancel(scope.row)"
                    >
                        <el-button
                            type="danger"
                            size="mini"
                            plain
                            slot="reference"
                            >撤单</el-button
                        >
                    </el-popconfirm> -->
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            background
            layout="prev, pager, next, jumper"
            :total="total"
            @current-change="setPageIndx"
        >
        </el-pagination>
        <el-dialog
            :visible.sync="policyDetailVis"
            title="增减人"
            width="77%"
            modal
            :close-on-click-modal="false"
        >
            <div class="stateText">
                {{ showState(current) }}
            </div>
            <el-row :gutter="16" class="policyDetail">
                <el-col :span="12">
                    <label>编号</label>
                    <span>{{ current.Insurance_Number }}</span>
                </el-col>
                <el-col :span="12">
                    <label>旅行社编号</label>
                    <span>{{ current.Insurance_TravelAgencyNumber }}</span>
                </el-col>
                <el-col :span="12">
                    <label>旅行社名称</label>
                    <span>{{ current.Insurance_TravelAgencyName }}</span>
                </el-col>
                <el-col :span="12">
                    <label>导游姓名</label>
                    <span>{{ current.Insurance_Guide }}</span>
                </el-col>
                <el-col :span="12">
                    <label>旅游目的地</label>
                    <span>{{ current.Insurance_Destination }}</span>
                </el-col>
                <el-col :span="12">
                    <label>保险期限</label>
                    <span>{{
                        current.Insurance_EffectStart +
                        " - " +
                        current.Insurance_EffectEnd
                    }}</span>
                </el-col>
                <el-col :span="12">
                    <label>产品名称</label>
                    <span>{{ current.Insurance_PolicyName }}</span>
                </el-col>
                <el-col :span="12">
                    <label>投保人数</label>
                    <span>{{ current.Insurance_TotalPerson }}位</span>
                </el-col>
                <el-col :span="24" v-show="companyid == 46 || companyid == 280">
                    <label>产品描述</label>
                    <span>{{ current.Insurance_PolicyBrief }}</span>
                </el-col>
            </el-row>
            <div class="policyPerson">
                <div>
                    <h5>汇总</h5>
                    <table>
                        <tr v-for="(item, index) in policylist" :key="index">
                            <td>{{ item.Policy_Name }}</td>
                            <td>
                                {{ item.PolicyTravel_PersonList.length }} 人
                            </td>
                            <td>{{ current.Insurance_DelayMoney }} 元</td>
                        </tr>
                    </table>
                </div>
                <div>
                    <span>被保险人列表</span>
                    <!-- <template
                        v-if="
                            current.Insurance_Status != 2 &&
                            current.Insurance_Type == 0
                        "
                    >
                        <el-button
                            type="primary"
                            size="mini"
                            plain
                            @click="beforeAdd(false)"
                            >增加被保险人</el-button
                        >
                        <el-button
                            type="primary"
                            size="mini"
                            plain
                            @click="beforeAdd(true)"
                            >增加被保险人(护照)</el-button
                        >
                    </template> -->
                </div>
                <template v-if="current.Insurance_Type == 0">
                    <el-row
                        :gutter="6"
                        v-for="(item, indx) in persons"
                        :key="indx"
                        :class="{
                            'del-mark': item.InsurancePerson_Status == 0,
                            'add-porp': item.InsurancePerson_Add == 1,
                        }"
                    >
                        <el-col :span="2">
                            <label> {{ indx + 1 }}.</label>
                        </el-col>
                        <el-col :span="6">
                            <label>姓名</label>
                            <span>{{ item.InsurancePerson_Name }}</span>
                        </el-col>
                        <el-col :span="10">
                            <label>证件</label>
                            <span>{{ item.InsurancePerson_CertNo }}</span>
                        </el-col>
                        <el-col :span="6">
                            <el-button
                                type="primary"
                                size="mini"
                                plain
                                @click="printNow(current, indx)"
                                >打印</el-button
                            >
                            <!-- <el-popconfirm
                                v-if="current.Insurance_Status != 2"
                                confirm-button-text="好的"
                                cancel-button-text="再考虑"
                                icon="el-icon-info"
                                icon-color="red"
                                title="确定要删除吗？"
                                @confirm="
                                    delPerson(item.InsurancePerson_ID, indx)
                                "
                            >
                                <el-button
                                    type="danger"
                                    size="mini"
                                    plain
                                    slot="reference"
                                    v-if="item.InsurancePerson_Status != 0"
                                    >删除</el-button
                                >
                            </el-popconfirm> -->
                        </el-col>
                    </el-row>
                </template>
                <template v-else>
                    <div class="img-show">
                        <img
                            style="width: 90%"
                            :src="current.Insurance_PersonImage"
                            alt=""
                            srcset=""
                        />
                    </div>
                </template>
            </div>
        </el-dialog>
        <el-dialog
            :visible.sync="addShow"
            title="添加被保险人"
            width="37%"
            modal
            :close-on-click-modal="false"
        >
            <el-row :gutter="12" class="addPersonwrp">
                <el-col :span="12">
                    <label>姓名</label>
                    <el-input v-model="InsurancePerson_Name"></el-input>
                </el-col>
                <el-col :span="12">
                    <label>{{ addForeigner ? "护照" : "证件" }}</label>
                    <el-input v-model="InsurancePerson_CertNo"></el-input>
                </el-col>
                <template v-if="addForeigner">
                    <el-col :span="12">
                        <label>性别</label>
                        <el-autocomplete
                            type="text"
                            style="width: 100%"
                            v-model="InsurancePerson_Gender"
                            :fetch-suggestions="querySearch"
                            placeholder="输入被保险人性别"
                        >
                        </el-autocomplete>
                    </el-col>
                    <el-col :span="12">
                        <label>生日</label>
                        <el-date-picker
                            style="width: 100%"
                            v-model="InsurancePerson_Birthday"
                            type="date"
                            placeholder="选择被保险人生日日期"
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                    </el-col>
                </template>
            </el-row>
            <el-row class="bottom-btn-wrp">
                <el-button
                    type="default"
                    size="mini"
                    plain
                    @click="cancelAddPerson"
                    >取消</el-button
                >
                <el-button type="primary" size="mini" plain @click="addPerson"
                    >确定</el-button
                >
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            openName: ["1"],
            orders: [],
            persons: [],
            currentID: "",
            current: "",
            policylist: "",
            searchcertno: "",
            InsurancePerson_Name: "",
            InsurancePerson_CertNo: "",
            InsurancePerson_Birthday: "2020-01-01",
            InsurancePerson_Gender: "男",
            addPersonArr: [
                {
                    InsurancePerson_Name: "",
                    InsurancePerson_CertNo: "",
                },
            ],
            downloadDetailUrl: "",
            downloadUrl: "",
            addForeigner: false,
            downloadShow: false,
            downloadShowDetail: false,
            addShow: false,
            policyDetailVis: false,
            pageindex: 1,
            pagesize: 10,
            total: 0,
            personcountall: 0,
            moneyall: 0,
            timemethod: 0,
            starttime: "",
            endtime: "",
            teamnumber: "",
            searchnumber: "",
            status: "",
            policyquality: "",
            agencylist: "",
            policyItem: "",
            ProductList: [],
            companyid: 0,
        };
    },
    mounted() {
        this.setDateOfTwo();
        this.pullList();
        this.companyid = sessionStorage.getItem("companyid");
    },
    methods: {
        prevMonth() {
            let nDate = new Date();
            let nMonth = nDate.getMonth();
            let nYear = nDate.getFullYear();

            this.endtime = nYear + "-" + (nMonth + 1) + "-" + "01";

            if (nMonth == 0) {
                nMonth = 11;
                nYear = nYear - 1;
            } else {
                nMonth = nMonth - 1;
            }

            this.starttime = nYear + "-" + (nMonth + 1) + "-" + "01";

            console.log("上月", this.starttime, this.endtime);
        },
        curtMonth() {
            let nDate = new Date();
            let nMonth = nDate.getMonth();
            let nYear = nDate.getFullYear();

            this.starttime = nYear + "-" + (nMonth + 1) + "-" + "01";

            if (nMonth == 11) {
                nMonth = 0;
                nYear = nYear + 1;
            } else {
                nMonth = nMonth + 1;
            }

            this.endtime = nYear + "-" + (nMonth + 1) + "-" + "01";

            console.log("当月", this.starttime, this.endtime);
        },
        addPorpClass(item) {
            // console.log(item.row)
            if (item.row.Insurance_Add == 1) {
                return "add-porp";
            } else {
                return "";
            }
        },
        async printNow(item, indx) {
            console.log("打印",item,indx)
            let detail = await this.ajx(
                "/AdminTravel/InsuranceTravel.ashx?type=selectOne",
                { id: item.Insurance_ID }
            );
            console.log(detail.model, detail.list);
            this.printShow(detail, indx);
        },
        async getDown(id) {
            let rb = await this.ajx(
                "/AdminTravel/InsuranceTravel.ashx?type=downloadword",
                { id: id }
            );
            // console.log("down",rb)
            window.open(this.domin + rb.url);
        },
        exportOrderList() {
            this.downloadShow = true;
            this.ipost(
                "/AdminTravel/InsuranceTravel.ashx?type=exporttoexcel",
                {
                    pageindex: this.pageindex,
                    pagesize: this.pagesize,
                    timemethod: this.timemethod,
                    starttime: this.starttime,
                    endtime: this.endtime,
                    teamnumber: this.teamnumber,
                    searchnumber: this.searchnumber,
                    status: this.status,
                    policyquality: this.policyquality,
                },
                (res) => {
                    this.downloadUrl = res.url;
                }
            );
        },
        exportDetailList() {
            this.downloadShowDetail = true;
            this.ipost(
                "/AdminTravel/InsuranceTravel.ashx?type=exporttoexceldetail",
                {
                    pageindex: this.pageindex,
                    pagesize: this.pagesize,
                    timemethod: this.timemethod,
                    starttime: this.starttime,
                    endtime: this.endtime,
                    teamnumber: this.teamnumber,
                    searchnumber: this.searchnumber,
                    status: this.status,
                    policyquality: this.policyquality,
                },
                (res) => {
                    this.downloadDetailUrl = res.url;
                }
            );
        },
        showState(itm) {
            if (itm) {
                let now = Date.now();
                // console.log("itm",itm)
                // console.log(Date.parse(itm.Insurance_EffectStart))
                // console.log(Date.parse(itm.Insurance_EffectEnd))
                let textStr = "";
                if (itm.Insurance_Status == 2) {
                    textStr = "撤单";
                } else if (itm.Insurance_Status == 1) {
                    if (now < Date.parse(itm.Insurance_EffectStart)) {
                        textStr = "已提交";
                    } else if (
                        Date.parse(itm.Insurance_EffectStart) <= now &&
                        now <= Date.parse(itm.Insurance_EffectEnd)
                    ) {
                        textStr = "已生效";
                    } else if (now > Date.parse(itm.Insurance_EffectEnd)) {
                        textStr = "已过期";
                    } else {
                        textStr = "未知";
                    }
                }
                // console.log("textStr", textStr, textStr.length)
                return textStr;
            }
        },
        setProudctName() {
            let current = this.ProductList.find(
                (item) => item.Policy_Name == this.policyItem
            );
            this.policyid = current.Policy_ID;

            console.log("policyid:", current.Policy_ID);

            this.Policy_Name = this.policyItem;
        },
        passStr(itm) {
            // console.log(itm)
            let psarr = [];
            for (let i = 1; i <= 5; i++) {
                if (itm["Insurance_Pass" + i] !== "") {
                    psarr.push(itm["Insurance_Pass" + i]);
                }
            }
            let psstr = psarr.join(" - ");
            return psstr;
        },

        setDateOfTwo() {
            let now = new Date();
            this.starttime = now
                .toLocaleDateString("zh-CN")
                .replace(/\//g, "-");
            let nextDay = new Date(
                Date.parse(new Date(this.starttime)) + 24 * 60 * 60 * 1000
            );
            this.endtime = nextDay
                .toLocaleDateString("zh-CN")
                .replace(/\//g, "-");
        },
        beforeAdd(bool) {
            this.addForeigner = bool;
            this.addShow = true;
            this.InsurancePerson_Name = "";
            this.InsurancePerson_CertNo = "";
        },
        cancelAddPerson() {
            this.addShow = false;
            this.InsurancePerson_Name = "";
            this.InsurancePerson_CertNo = "";
        },
        querySearch(qs, cb) {
            cb([
                { name: "男", value: "男" },
                { name: "女", value: "女" },
            ]);
        },
        addPerson() {
            let type = this.addForeigner ? "insertpassport" : "insert";

            if (
                this.InsurancePerson_Name == "" ||
                this.InsurancePerson_CertNo == ""
            ) {
                this.$message("请确认您填写内容完整！");
            }

            let tempModel = {
                InsurancePerson_Insurance: this.current.Insurance_ID,
                InsurancePerson_Name: this.InsurancePerson_Name,
                InsurancePerson_CertNo: this.InsurancePerson_CertNo,
            };

            if (this.addForeigner) {
                tempModel.InsurancePerson_Birthday =
                    this.InsurancePerson_Birthday;
                tempModel.InsurancePerson_Gender = this.InsurancePerson_Gender;
            }

            this.ipost(
                "/AdminTravel/InsuranceTravelPerson.ashx?type=" + type,
                {
                    model: JSON.stringify(tempModel),
                },
                (res) => {
                    this.addShow = false;
                    this.getDetails(this.currentID);
                    this.$message("添加成功");
                    this.pullList();
                }
            );
        },
        delPerson(id, indx) {
            console.log("indx", id);
            this.ipost(
                "/AdminTravel/InsuranceTravelPerson.ashx?type=delete",
                {
                    id: id,
                },
                (res) => {
                    this.persons.splice(indx, 1);
                    this.$message("删除成功");
                }
            );
        },
        setCancel(itm) {
            this.ipost(
                "/AdminTravel/InsuranceTravel.ashx?type=cancel",
                {
                    id: itm.Insurance_ID,
                },
                (res) => {
                    this.pullList();
                    this.$message("作废成功");
                }
            );
        },
        getDetails(id) {
            this.currentID = id;
            this.ipost(
                "/AdminTravel/InsuranceTravel.ashx?type=selectOne",
                {
                    id: id,
                },
                (res) => {
                    // console.log("res:", res)
                    this.current = res.model;
                    this.persons = res.list;
                    this.policylist = res.policylist;
                    this.policyDetailVis = true;
                }
            );
        },
        getPolicyList() {
            this.ProductList.length = 0;
            this.policyItem = "";
            // this.ipost(
            //     "/AdminTravel/PolicyTravel.ashx?type=selectbyagency", {
            //     pageindex: 1,
            //     pagesize: 666,
            //     quality: this.policyquality
            // },
            //     (res) => {
            //         this.ProductList = res.list
            //         // this.total = res.count
            //         // console.log("res:", res)
            //     }
            // );
        },
        setPageIndx(pgindx) {
            this.pageindex = pgindx;
            this.pullList();
        },
        pullList(num) {
            if (num) {
                this.pageindex = num;
            }
            this.ipost(
                "/AdminTravel/InsuranceTravel.ashx?type=select",
                {
                    searchcertno: this.searchcertno,
                    pageindex: this.pageindex,
                    pagesize: this.pagesize,
                    timemethod: this.timemethod,
                    starttime: this.starttime,
                    endtime: this.endtime,
                    teamnumber: this.teamnumber,
                    searchnumber: this.searchnumber,
                    status: this.status,
                    policyquality: this.policyquality,
                    agencylist: this.agencylist,
                },
                (res) => {
                    this.orders = res.list;
                    this.total = res.count;
                    this.personcountall = res.personcountall;
                    this.moneyall = res.moneyall;
                    // console.log("res:", res)
                }
            );
        },
    },
};
</script>

<style scoped>
.date-select-wrp {
    position: relative;
}
.date-select-wrp:hover .pgf-tips {
    display: block;
}
.pgf-tips {
    display: none;
    position: absolute;
    top: -30px;
    right: 12px;
    right: 120px;
    font-size: 12px;
    font-weight: bold;
    text-align: justify;
    line-height: 1;
    color: red;
    z-index: 1;
}
.moon-icon {
    width: 12px;
}
.two-button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 60px;
    height: 40px;
    margin-left: 5px;
}
.two-button a {
    display: flex;
    justify-content: flex-start;
    margin-right: 3px;
    display: block;
    height: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    line-height: 18px;
    transition: all 1s ease;
}
.two-button a:active {
    background-color: #569bf7;
}
.el-table .add-porp {
    color: #1d82fe !important;
    font-weight: bold;
}
.img-show {
    padding: 12px;
    text-align: center;
}

.del-mark > div > span {
    text-decoration: line-through;
    text-decoration-color: #1d82fe;
    position: relative;
}

.downBtn a {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 6px;
    line-height: 40px;
    text-align: center;
    padding: 0 12px;
}

.downBtn a:hover {
    border-color: #1d82fe;
    color: #1d82fe;
}

.btnCol .el-button {
    margin-right: 8px;
}

.policyPerson .el-col {
    margin: 10px 0;
}

.policyPerson .el-button {
    margin-left: 8px;
}

.bottom-btn-wrp {
    text-align: center;
    padding: 20px 0 0;
}

.addPersonwrp label {
    line-height: 2.3;
}

.stateText {
    display: inline-block;
    font-size: 27px;
    color: tomato;
    font-weight: bold;
    padding: 10px;
    border: 3px solid red;
    text-align: center;
    border-radius: 4px;
    font-family: Georgia, "Times New Roman", Times, serif;
    position: absolute;
    top: 130px;
    left: 70%;
    z-index: 10;
    transform: translate(-50%, -50%) rotateZ(-35deg);
}

.policyPerson {
    margin-top: 27px;
    padding-top: 27px;
    border-top: 2px dashed #efefef;
}

.policyDetail label,
.policyPerson label {
    color: #999;
    margin-right: 12px;
    line-height: 2.1;
}

.policyPerson span,
.policyDetail span {
    font-weight: bold;
    font-size: 15px;
}

.dataFilterWrap {
    padding: 10px;
}

.dataFilterWrap .el-row {
    margin: 10px;
}

.dataFilterWrap label {
    display: inline-block;
    width: 70px;
    margin-right: 6px;
}

.dataFilterWrap .el-select,
.dataFilterWrap .el-input {
    width: 220px;
}
</style>
