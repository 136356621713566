<template>
    <div>
        <div class="dataFilterWrap">
            <el-row :gutter="3">
                <el-col :span="6">
                    <label>时间类型</label>
                    <el-select v-model="timemethod" placeholder="请选择">
                        <el-option :value="0" label="提交时间"></el-option>
                        <el-option :value="1" label="起保时间"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="10">
                    <label>时间范围</label>
                    <el-date-picker
                        v-model="starttime"
                        type="date"
                        placeholder="选择开始日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                    ></el-date-picker>
                    <el-date-picker
                        v-model="endtime"
                        type="date"
                        placeholder="选择结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                    ></el-date-picker>
                </el-col>
                <el-col :span="4">
                    <label>旅行社</label>
                    <el-button type="primary" plain round @click="showTravelAgency">选择</el-button>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" round plain @click="pullList">确定</el-button>
                </el-col>
            </el-row>
        </div>
        <el-table :data="orders" stripe fit tooltip-effect="dark" class="orderTable">
            <el-table-column label="旅行社" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.TravelAgency_Name }}</template>
            </el-table-column>
            <el-table-column label="人数">
                <template slot-scope="scope">{{ scope.row.TravelAgency_TotalCount }}</template>
            </el-table-column>
            <el-table-column label="份数">
                <template slot-scope="scope">{{ scope.row.TravelAgency_Total }}</template>
            </el-table-column>
            <el-table-column label="金额">
                <template
                    slot-scope="scope"
                >{{ Number(scope.row.TravelAgency_TotalMoney).toFixed(2) }}</template>
            </el-table-column>
        </el-table>

        <el-dialog :visible.sync="travelAgencyShow" title="旅游社列表" width="37%" modal>
            <div style="margin-bottom: 12px">
                <el-row :gutter="6">
                    <el-col :span="12">
                        <el-input
                            type="text"
                            v-model="searchtravel"
                            clearable
                            placeholder="请输入旅游社名关键字"
                        ></el-input>
                    </el-col>
                    <el-col :span="12">
                        <el-button @click="getTravelAgencs">搜索</el-button>
                    </el-col>
                </el-row>
            </div>
            <el-checkbox-group v-model="AgencyCheck" @change="AgencyChange">
                <el-checkbox
                    v-for="Agency in travelAgencies"
                    :label="Agency.TravelAgency_ID"
                    :key="Agency.TravelAgency_Name"
                >{{ Agency.TravelAgency_Name }}</el-checkbox>
            </el-checkbox-group>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            orders: [],
            travelAgencies: [],
            starttime: "",
            endtime: "",
            AgencyCheck:[],
            timemethod: "",
            searchtravel: "",
            travelAgencyShow: false,
        };
    },
    mounted() {
        this.pullList();
        this.getTravelAgencs();
    },
    methods: {
        showTravelAgency() {
            this.travelAgencyShow = true;
        },
        AgencyChange(val) {
            console.log(val);
            console.log(this.AgencyCheck);
            this.agencylist = this.AgencyCheck.join(",");
        },
        getTravelAgencs() {
            let type = "select";
            console.log("current route", this.$route);
            if (this.$route.matched[0]["path"] == "/area") {
                type = "selectbytravelarea";
            }
            this.ipost(
                "/AdminTravel/TravelAgency.ashx?type="+type,
                {
                    keyword: this.searchtravel,
                    pageindex: 1,
                    pagesize: 999,
                },
                (res) => {
                    this.travelAgencies = res.list;
                }
            );
        },
        pullList(num) {
            // 管理员 statisticstravelagencyuestime 分支 statisticstravelareausetime
            let type = "statisticstravelagencyuestime";
            console.log("current route", this.$route);
            if (this.$route.matched[0]["path"] == "/area") {
                type = "statisticstravelareausetime";
            }

            this.ipost(
                "/AdminTravel/CashTravel.ashx?type=" + type,
                {
                    timemethod: this.timemethod,
                    pageindex: this.pageindex,
                    pagesize: this.pagesize,
                    starttime: this.starttime,
                    endtime: this.endtime,
                    agencylist: this.agencylist,
                },
                (res) => {
                    this.orders = res.list;
                }
            );
        },
    },
};
</script>

<style scoped>
.img-show {
    padding: 12px;
    text-align: center;
}

.downBtn a {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 6px;
    line-height: 40px;
    text-align: center;
    padding: 0 12px;
}

.del-mark {
    text-decoration: line-through;
    position: relative;
}

.del-mark::after {
    content: "";
    display: block;
    left: 0;
    right: 0;
    top: 50%;
    height: 1px;
    border-top: 1px solid #333;
}

.downBtn a:hover {
    border-color: #1d82fe;
    color: #1d82fe;
}

.btnCol .el-button {
    margin-right: 8px;
}

.policyPerson .el-col {
    margin: 10px 0;
}

.policyPerson .el-button {
    margin-left: 8px;
}

.bottom-btn-wrp {
    text-align: center;
    padding: 20px 0 0;
}

.addPersonwrp label {
    line-height: 2.3;
}

.stateText {
    display: inline-block;
    font-size: 27px;
    color: tomato;
    font-weight: bold;
    padding: 10px;
    border: 3px solid red;
    text-align: center;
    border-radius: 4px;
    font-family: Georgia, "Times New Roman", Times, serif;
    position: absolute;
    top: 130px;
    left: 70%;
    z-index: 10;
    transform: translate(-50%, -50%) rotateZ(-35deg);
}

.policyPerson {
    margin-top: 27px;
    padding-top: 27px;
    border-top: 2px dashed #efefef;
}

.policyDetail label,
.policyPerson label {
    color: #999;
    margin-right: 12px;
    line-height: 2.1;
}

.policyPerson span,
.policyDetail span {
    font-weight: bold;
    font-size: 15px;
}

.dataFilterWrap {
    padding: 10px 20px;
}

.dataFilterWrap .el-row {
    margin: 20px 12px;
}

.dataFilterWrap label {
    display: inline-block;
    width: 70px;
    margin-right: 12px;
}

.dataFilterWrap .el-select,
.dataFilterWrap .el-input {
    width: 220px;
}
</style>
